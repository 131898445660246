.custom-table {
    display: block;
    width: 100%;
    border-collapse: collapse;
}

.custom-table th,
.custom-table td {
    padding: 8px 20px; /* Adjusted padding for both horizontal and vertical */
    text-align: left;
    vertical-align: top;
    border: 1px solid #dee2e6;
    white-Space: 'nowrap';
}

@media (max-width: 900px) {
    .custom-table thead {
        display: none;
    }

    .custom-table tbody {
        display: block;
    }

    .custom-table tr {
        display: block;
        margin-bottom: 1px; /* Add some space between rows */
    }

    .custom-table td {
        display: block;
        text-align: justify;
    }

    /* Define styles for alternating row colors */
.evenRow {
    background-color: #1eb859; /* Light shade */
  }
  
  .oddRow {
    background-color: #ca2525; /* Slightly darker shade */
  }
  .yellowRow {
    background-color: rgb(238, 240, 159) !important;}
  
}

  
@media (max-width: 900px) {
  .chart-container {
    grid-template-columns: 1fr; /* One chart per row for small screens */
  }
}


  .charts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .chart-item {
    flex: 1 1 calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    background-color: #fff;
  }

  /* MultiSelectStyles.css */ 
  .multi-select { width: 300px; /* Adjust the width as needed */ } 
  .multi-select .dropdown-container { width: 100%; }
        
         
        
      
  