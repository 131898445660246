/* styles.css */
.table-container {
    margin: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
  }
  
  table th,
  table td {
    border: 1px solid #ddd; /* Light grey border */
    padding: 8px;
    text-align: left; /* Left-align all cells */
  }
  
  table th {
    background-color: #f2f2f2; /* Light grey background for headings */
    font-weight: bold;
  }
  
  table tr:nth-child(even) {
    background-color: #fffff0; /* Very light yellow for even rows */
  }
  
  table tr:nth-child(odd) {
    background-color: #ffffff; /* White for odd rows */
  }
  
  table tr:hover {
    background-color: #f5f5f5; /* Light grey hover effect */
  }
  
  select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  button {
    padding: 5px 10px;
    background-color: #4caf50; /* Green background */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: #cccccc; /* Grey background for disabled state */
    cursor: not-allowed;
  }