.form-container {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  
  .custom-row {
    margin-left: 0;
    margin-right: 0;
  }
  
  .custom-col {
    padding-left: 0;
    padding-right: 0;
  }